<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">Cookie Details</sdHeading>
        <span>Set Up Your Cookie Information</span>
      </div>
    </template>
    <BasicFormWrapper>
      <sdHeading as="h4">Cookie Popup Details</sdHeading>
      <a-form :model="form" :rules="rules" @submit="onCookieDetailsSubmit">
        <a-row :gutter="25" style="margin-bottom: 26px">
          <a-col :xl="12" :lg="12" :xs="24">
            <a-form-item name="cookie_logo" label="Cookie Logo">
              <a-input
                type="file"
                id="meta-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'cookie_logo')"
              />
            </a-form-item>
            <div v-if="otherData.cookie_logo_url" class="img-thumb-cls">
              <img alt="Cookie Logo" :src="otherData.cookie_logo_url" />
            </div>
          </a-col>
        </a-row>
        <!-- <a-row style="margin-bottom: 26px">
          <a-col :xs="24" :lg="24">
            <a-form-item name="cookie_content" label="Cookie Content">
              <ckeditor
                :editor="editor"
                v-model="form.cookie_content"
                :config="editorConfig"
              />
            </a-form-item>
          </a-col>
        </a-row> -->
        <div class="setting-form-actions text-center">
          <sdButton size="default" htmlType="submit" type="primary">
            Update
          </sdButton>
        </div>
      </a-form>
    </BasicFormWrapper>
  </sdCards>
</template>

<script>
import { mapActions } from "vuex";
import { defineComponent } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { BasicFormWrapper } from "../../../styled";
import Notif from "@/services/Notification";

const Cookie = defineComponent({
  name: "Cookie",
  components: { BasicFormWrapper },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      form: {
        cookie_content: "",
        cookie_logo: null,
      },
      otherData: {
        cookie_logo_url: "",
      },
      rules: {},
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.authUser;
    },
  },
  beforeMount() {},
  methods: {
     ...mapActions(["updateCookie","getProfile"]),
    handleCancel(e) {
      e.preventDefault();
    },
    async onCookieDetailsSubmit(e) {
      e.preventDefault();

      try {
        await this.updateCookie(this.form);
      } catch (error) {
        if (error?.message) {
          Notif.show(error?.message, { type: "error", title: "Error" });
        }
      }

    },
    onImageChange(e, type) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      if (type == "cookie_logo") {
        this.otherData.cookie_logo_url = blobURL;
        this.form.cookie_logo = file;
        return null;
      }
    },
    async getCookiesData() {
      try {

        await this.getProfile();

        const { cookie_content = '' , cookie_logo = ""} = this.user;

        this.otherData.cookie_logo_url = cookie_logo;
        
        setTimeout(() => {
          this.form.cookie_content = cookie_content;
        }, 1000);
        
      } catch (error) {
         if (error?.message) {
          Notif.show(error?.message, { type: "error", title: "Error" });
        }
      }

    }
  },
  created() {
    this.getCookiesData();
    // const rules = {};
    // for (const [key] of Object.entries(this.form)) {
    //   rules[key] = [
    //     {
    //       required: true,
    //       message: `Please enter ${key.replace("_", " ")}`,
    //       trigger: "blur",
    //     },
    //   ];
    // }
    // this.rules = rules;
  },
});

export default Cookie;
</script>
